var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('InvoiceModal',{attrs:{"trip":_vm.invoiceModalDetails,"symbol":_vm.symbol}}),_c('RefundModal',{attrs:{"paymentSourceEnabled":""},on:{"refunded":_vm.onRefunded}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('div',[_c('title-plus',{attrs:{"title":_vm.$t('components.revenueManagement.headline'),"hide-plus":true}})],1),_c('div',{staticClass:"flex md:justify-end"},[_c('date-range-picker',{on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1)]),_c('div',{staticClass:"flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row"},[_c('SummaryCard',{attrs:{"title":_vm.$t('components.revenueManagement.summary.totalRevenue'),"value":((_vm.indexMetaData.summary.currency.symbol) + " " + (_vm.indexMetaData.summary.total_debit)),"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.revenueManagement.summary.avgRevenuePerDay'),"value":((_vm.indexMetaData.summary.currency.symbol) + " " + (_vm.indexMetaData.summary.avg_debit_per_day)),"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.revenueManagement.summary.totalRefundAmount'),"value":((_vm.indexMetaData.summary.currency.symbol) + " " + (_vm.indexMetaData.summary
              .total_refunded_amount || 0)),"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.revenueManagement.summary.totalCardAmount'),"value":((_vm.indexMetaData.summary.currency.symbol) + " " + (_vm.indexMetaData.summary
              .total_card_amount || 0)),"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.revenueManagement.summary.totalWalletAmount'),"value":((_vm.indexMetaData.summary.currency.symbol) + " " + (_vm.indexMetaData.summary
              .total_wallet_amount || 0)),"variant":"gray"}})],1)]),(_vm.$acl.canView('transaction'))?[_c('FSTable',{attrs:{"qso":_vm.qso,"fst-id":_vm.fstId,"headers":_vm.getTableHeaders,"exportFor":"revenue","endpoint":_vm.endpoint,"currency-enabled":true,"currency-attributes":_vm.getCurrencyAttributes},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
              var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":_vm.fstId,"slot-width":slotWidth,"options":_vm.getFilteredItems}})],1)]}},{key:"default",fn:function(ref){
              var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,attrs:{"className":{
                  'fst-row': true,
                  'host-child-trip-row': item.child_invoices.length > 0,
                },"text-fallback-always":""},on:{"meta":function (e) { return (_vm.indexMetaData = e); }}},[(_vm.isTripOrRentExist(item))?_c('FSTableRowItem',{attrs:{"text-fallback-always":""}},[_c('router-link',{class:"text-blue-600",attrs:{"to":{
                      name: item.rental ? 'VehicleRentalDetail' : 'ViewTrip',
                      params: {
                        id: ("" + (item.rental ? item.rental : item.trip)),
                      },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.id.substr(item.id.length - 5))+" ")]),(item.child_invoices.length > 0)?_c('span',{staticClass:"bg-blue-600 child-invoice-index-badge "},[_vm._v("E")]):_vm._e()],1):_c('FSTableRowItem',[_vm._v(" "+_vm._s(item.id.substr(item.id.length - 5))+" "),(item.child_invoices.length > 0)?_c('span',{staticClass:"bg-blue-600 child-invoice-index-badge "},[_vm._v("E")]):_vm._e()]),_c('FSTableRowItem',{attrs:{"text":item.tax_plan,"text-fallback":"--","truncate":-5}}),_c('FSTableRowItem',[(item.user)?_c('router-link',{staticClass:"font-extrabold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewRiderUserProfile',
                      params: { id: item.user.id },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.user.full_name)+" ")]):_c('span',[_vm._v(" -- ")])],1),_c('FSTableRowItem',[(item.user)?[_vm._v(" "+_vm._s(item.user.role_name)+" ")]:[_vm._v(" -- ")]],2),_c('FSTableRowItem',{attrs:{"text":item.date,"date":true}}),(!item.payment_method_details)?_c('FSTableRowItem',{attrs:{"text":item.payment_method}}):_vm._e(),(item.payment_method_details)?_c('FSTableRowItem',[(item.payment_method && item.payment_method !== '--')?_c('i',{class:_vm.getPaymentCardIconClass(item.payment_method_details)}):_vm._e(),(item.payment_method && item.payment_method !== '--')?_c('span',[_vm._v("  ")]):_vm._e(),_vm._v(" "+_vm._s(item.payment_method)+" ")]):_vm._e(),_c('FSTableRowItem',{attrs:{"text":_vm.getPaymentForText(item.payment_for)}}),_c('FSTableRowItem',{attrs:{"text":item,"as-currency":""}}),_c('FSTableRowItem',{attrs:{"text":item.payment_reference_id,"text-fallback":"--"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var text = ref.text;
return [(text === '--')?_c('span',[_vm._v("--")]):_c('span',[(_vm.paymentMethod === 'Stripe')?_c('span',{staticClass:"text-blue-500"},[_c('a',{attrs:{"target":"_blank","href":("https://dashboard.stripe.com/payments/" + text)}},[_vm._v(_vm._s(_vm.truncate(text, -5, '')))]),_c('span',{staticClass:"cursor-pointer text-gray-500",on:{"click":function($event){return _vm.copyTextToClipboard(
                              ("https://dashboard.stripe.com/payments/" + text)
                            )}}},[_c('i',{staticClass:"ml-2 far fa-copy"})])]):_c('span',[_vm._v(_vm._s(_vm.truncate(text, -5, '')))])])]}}],null,true)}),_c('FSTableRowItem',[_c('OtoEyeIcon',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('View Invoice'),expression:"'View Invoice'",modifiers:{"bottom":true}}],on:{"click":function($event){return _vm.onInvoiceModalReq(item.trip, false)}}})],1),_c('FSTableRowItem',{staticClass:"flex gap-1"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(
                      _vm.getRefundTooltip(item, { ignoreSource: true })
                    ),expression:"\n                      getRefundTooltip(item, { ignoreSource: true })\n                    ",modifiers:{"bottom":true}}],staticClass:"refund-action",class:item.is_refunded ? 'refunded' : 'refund',on:{"click":function($event){return _vm.showRefundPopup(item)}}},[_vm._v(" "+_vm._s(item.is_refunded ? _vm.$t('components.revenueManagement.actions.refunded') : _vm.$t('components.revenueManagement.actions.refund'))+" ")]),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex),attrs:{"id":("more-actions-" + itemIndex),"data":item},on:{"delete":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)],1),_vm._l((item.child_invoices),function(childItem,childItemIndex){return _c('FSTableRow',{key:("child-" + itemIndex + "-" + childItemIndex),attrs:{"className":"fst-row child-trip-row","text-fallback-always":""}},[(_vm.isTripOrRentExist(childItem))?_c('FSTableRowItem',[_c('router-link',{class:"text-blue-600",attrs:{"to":{
                      name: childItem.rental
                        ? 'VehicleRentalDetail'
                        : 'ViewTrip',
                      params: {
                        id: ("" + (childItem.rental ? childItem.rental : childItem.trip)),
                      },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(childItem.id.substr(childItem.id.length - 5))+" ")]),_c('span',{staticClass:"bg-gray-400 child-invoice-index-badge "},[_vm._v(_vm._s(childItemIndex + 1)+" ")])],1):_c('FSTableRowItem',[_vm._v(" "+_vm._s(childItem.id.substr(childItem.id.length - 5))+" "),_c('span',{staticClass:"bg-gray-400 child-invoice-index-badge "},[_vm._v(_vm._s(childItemIndex + 1)+" ")])]),_c('FSTableRowItem',{attrs:{"text":childItem.tax_plan,"text-fallback":"--","truncate":-5}}),_c('FSTableRowItem',[(childItem.user)?_c('router-link',{staticClass:"font-extrabold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewRiderUserProfile',
                      params: { id: childItem.user.id },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(childItem.user.full_name)+" ")]):_c('span',[_vm._v(" -- ")])],1),_c('FSTableRowItem',[(childItem.user)?[_vm._v(" "+_vm._s(childItem.user.role_name)+" ")]:[_vm._v(" -- ")]],2),_c('FSTableRowItem',{attrs:{"text":childItem.date,"date":true}}),(!childItem.payment_method_details)?_c('FSTableRowItem',{attrs:{"text":childItem.payment_method}}):_vm._e(),(childItem.payment_method_details)?_c('FSTableRowItem',[(
                      childItem.payment_method &&
                        childItem.payment_method !== '--'
                    )?_c('i',{class:_vm.getPaymentCardIconClass(
                        childItem.payment_method_details
                      )}):_vm._e(),(
                      childItem.payment_method &&
                        childItem.payment_method !== '--'
                    )?_c('span',[_vm._v("  ")]):_vm._e(),_vm._v(" "+_vm._s(childItem.payment_method)+" ")]):_vm._e(),_c('FSTableRowItem',{attrs:{"text":_vm.getPaymentForText(childItem.payment_for)}}),_c('FSTableRowItem',{attrs:{"text":childItem,"as-currency":""}}),_c('FSTableRowItem',{attrs:{"text":childItem.payment_reference_id,"text-fallback":"--"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var text = ref.text;
return [(text === '--')?_c('span',[_vm._v("--")]):_c('span',[(_vm.paymentMethod === 'Stripe')?_c('span',{staticClass:"text-blue-500"},[_c('a',{attrs:{"target":"_blank","href":("https://dashboard.stripe.com/payments/" + text)}},[_vm._v(_vm._s(_vm.truncate(text, -5, '')))]),_c('span',{staticClass:"cursor-pointer text-gray-500",on:{"click":function($event){return _vm.copyTextToClipboard(
                              ("https://dashboard.stripe.com/payments/" + text)
                            )}}},[_c('i',{staticClass:"ml-2 far fa-copy"})])]):_c('span',[_vm._v(_vm._s(_vm.truncate(text, -5, '')))])])]}}],null,true)}),_c('FSTableRowItem',[_c('OtoEyeIcon',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('View Invoice'),expression:"'View Invoice'",modifiers:{"bottom":true}}],on:{"click":function($event){return _vm.onInvoiceModalReq(childItem.trip, false)}}})],1),_c('FSTableRowItem',{staticClass:"flex gap-1"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(
                      _vm.getRefundTooltip(childItem, { ignoreSource: true })
                    ),expression:"\n                      getRefundTooltip(childItem, { ignoreSource: true })\n                    ",modifiers:{"bottom":true}}],staticClass:"refund-action",class:childItem.is_refunded ? 'refunded' : 'refund',on:{"click":function($event){return _vm.showRefundPopup(childItem)}}},[_vm._v(" "+_vm._s(childItem.is_refunded ? 'Refunded' : 'Refund')+" ")]),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex + "-" + childItemIndex),attrs:{"id":("more-actions-" + itemIndex + "-" + childItemIndex),"data":item},on:{"delete":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)],1)})]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"className":{
                  'fst-row': true,
                  'host-child-trip-row': item.child_invoices.length > 0,
                },"text-fallback-always":""},on:{"meta":function (e) { return (_vm.indexMetaData = e); }}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),(_vm.isTripOrRentExist(item))?_c('FSTableRowItem',{attrs:{"text-fallback-always":""}},[_c('router-link',{class:"text-blue-600",attrs:{"to":{
                      name: item.rental ? 'VehicleRentalDetail' : 'ViewTrip',
                      params: {
                        id: ("" + (item.rental ? item.rental : item.trip)),
                      },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.id.substr(item.id.length - 5))+" ")]),(item.child_invoices.length > 0)?_c('span',{staticClass:"bg-blue-600 child-invoice-index-badge "},[_vm._v("E")]):_vm._e()],1):_c('FSTableRowItem',[_vm._v(" "+_vm._s(item.id.substr(item.id.length - 5))+" "),(item.child_invoices.length > 0)?_c('span',{staticClass:"bg-blue-600 child-invoice-index-badge "},[_vm._v("E")]):_vm._e()]),(!item.payment_method_details)?_c('FSTableRowItem',{attrs:{"text":item.payment_method}}):_vm._e(),(item.payment_method_details)?_c('FSTableRowItem',[(item.payment_method && item.payment_method !== '--')?_c('i',{class:_vm.getPaymentCardIconClass(item.payment_method_details)}):_vm._e(),(item.payment_method && item.payment_method !== '--')?_c('span',[_vm._v("  ")]):_vm._e(),_vm._v(" "+_vm._s(item.payment_method)+" ")]):_vm._e(),_c('FSTableRowItem',{attrs:{"text":item,"as-currency":""}})],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.revenueManagement.table.columns.taxID'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.truncate(item.tax_plan, -5, '#') || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.revenueManagement.table.columns.rider'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[(item.user)?_c('router-link',{staticClass:"font-extrabold text-blue-600 capitalize",attrs:{"to":{
                          name: 'ViewRiderUserProfile',
                          params: { id: item.user.id },
                        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.user.full_name)+" ")]):_c('span',[_vm._v(" -- ")])],1)]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.payerRole' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.user.role_name || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.transactionTime' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getFormattedDateTime( item.date, 'D MMM, YY hh:mm:ss a' ) || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.paymentFor' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getPaymentForText(item.payment_for))+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.paymentRef' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[(item.payment_reference_id)?_c('span',[(_vm.paymentMethod === 'Stripe')?_c('span',{staticClass:"text-blue-500"},[_c('a',{attrs:{"target":"_blank","href":("https://dashboard.stripe.com/payments/" + (item.payment_reference_id))}},[_vm._v(_vm._s(_vm.truncate(item.payment_reference_id, -5, '')))]),_c('span',{staticClass:"cursor-pointer text-gray-500",on:{"click":function($event){return _vm.copyTextToClipboard(
                                ("https://dashboard.stripe.com/payments/" + (item.payment_reference_id))
                              )}}},[_c('i',{staticClass:"ml-2 far fa-copy"})])]):_c('span',[_vm._v(_vm._s(_vm.truncate(item.payment_reference_id, -5, '')))])]):_c('span',[_vm._v("--")])])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.invoice' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('OtoEyeIcon',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('View Invoice'),expression:"'View Invoice'",modifiers:{"bottom":true}}],on:{"click":function($event){return _vm.onInvoiceModalReq(item.trip, false)}}})],1)]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.actions' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex gap-1"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(
                            _vm.getRefundTooltip(item, { ignoreSource: true })
                          ),expression:"\n                            getRefundTooltip(item, { ignoreSource: true })\n                          ",modifiers:{"bottom":true}}],staticClass:"refund-action",class:item.is_refunded ? 'refunded' : 'refund',on:{"click":function($event){return _vm.showRefundPopup(item)}}},[_vm._v(" "+_vm._s(item.is_refunded ? 'Refunded' : 'Refund')+" ")]),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex + "-" + _vm.childItemIndex),attrs:{"id":("more-actions-" + itemIndex + "-" + _vm.childItemIndex),"data":item},on:{"delete":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])])])]):_vm._e(),_vm._l((item.child_invoices),function(childItem,childItemIndex){return [_c('FSTableRow',{key:("child-" + itemIndex + "-" + childItemIndex),attrs:{"className":"fst-row child-trip-row","text-fallback-always":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(("" + itemIndex + childItemIndex))}}},[(
                          _vm.opened.includes(("" + itemIndex + childItemIndex))
                        )?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),(_vm.isTripOrRentExist(childItem))?_c('FSTableRowItem',{attrs:{"text-fallback-always":""}},[_c('router-link',{class:"text-blue-600",attrs:{"to":{
                        name: childItem.rental
                          ? 'VehicleRentalDetail'
                          : 'ViewTrip',
                        params: {
                          id: ("" + (childItem.rental
                              ? childItem.rental
                              : childItem.trip)),
                        },
                      },"target":"_blank"}},[_vm._v(" "+_vm._s(childItem.id.substr(childItem.id.length - 5))+" ")]),_c('span',{staticClass:"bg-gray-400 child-invoice-index-badge "},[_vm._v(_vm._s(childItemIndex + 1)+" ")])],1):_c('FSTableRowItem',[_vm._v(" "+_vm._s(childItem.id.substr(childItem.id.length - 5))+" "),_c('span',{staticClass:"bg-gray-400 child-invoice-index-badge "},[_vm._v(_vm._s(childItemIndex + 1)+" ")])]),(!childItem.payment_method_details)?_c('FSTableRowItem',{attrs:{"text":childItem.payment_method}}):_vm._e(),(childItem.payment_method_details)?_c('FSTableRowItem',[(
                        item.payment_method && item.payment_method !== '--'
                      )?_c('i',{class:_vm.getPaymentCardIconClass(
                          childItem.payment_method_details
                        )}):_vm._e(),(
                        childItem.payment_method &&
                          childItem.payment_method !== '--'
                      )?_c('span',[_vm._v("  ")]):_vm._e(),_vm._v(" "+_vm._s(childItem.payment_method)+" ")]):_vm._e(),_c('FSTableRowItem',{attrs:{"text":childItem,"as-currency":""}})],1),(_vm.opened.includes(("" + itemIndex + childItemIndex)))?_c('FSTableRow',{key:childItemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.taxID' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.truncate(childItem.tax_plan, -5, '#') || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.rider' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[(childItem.user)?_c('router-link',{staticClass:"font-extrabold text-blue-600 capitalize",attrs:{"to":{
                            name: 'ViewRiderUserProfile',
                            params: { id: childItem.user.id },
                          },"target":"_blank"}},[_vm._v(" "+_vm._s(childItem.user.full_name)+" ")]):_c('span',[_vm._v(" -- ")])],1)]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.payerRole' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(childItem.user.role_name || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.transactionTime' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getFormattedDateTime( childItem.date, 'D MMM, YY hh:mm:ss a' ) || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.paymentFor' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getPaymentForText(childItem.payment_for))+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.paymentRef' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[(childItem.payment_reference_id)?_c('span',[(_vm.paymentMethod === 'Stripe')?_c('span',{staticClass:"text-blue-500"},[_c('a',{attrs:{"target":"_blank","href":("https://dashboard.stripe.com/payments/" + (childItem.payment_reference_id))}},[_vm._v(_vm._s(_vm.truncate( childItem.payment_reference_id, -5, '' )))]),_c('span',{staticClass:"cursor-pointer text-gray-500",on:{"click":function($event){return _vm.copyTextToClipboard(
                                  ("https://dashboard.stripe.com/payments/" + (childItem.payment_reference_id))
                                )}}},[_c('i',{staticClass:"ml-2 far fa-copy"})])]):_c('span',[_vm._v(_vm._s(_vm.truncate(childItem.payment_reference_id, -5, '')))])]):_c('span',[_vm._v("--")])])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.invoice' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('OtoEyeIcon',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('View Invoice'),expression:"'View Invoice'",modifiers:{"bottom":true}}],on:{"click":function($event){return _vm.onInvoiceModalReq(childItem.trip, false)}}})],1)]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.revenueManagement.table.columns.actions' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex gap-1"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(
                              _vm.getRefundTooltip(childItem, {
                                ignoreSource: true,
                              })
                            ),expression:"\n                              getRefundTooltip(childItem, {\n                                ignoreSource: true,\n                              })\n                            ",modifiers:{"bottom":true}}],staticClass:"refund-action",class:childItem.is_refunded ? 'refunded' : 'refund',on:{"click":function($event){return _vm.showRefundPopup(childItem)}}},[_vm._v(" "+_vm._s(childItem.is_refunded ? _vm.$t( 'components.revenueManagement.actions.refunded' ) : _vm.$t( 'components.revenueManagement.actions.refund' ))+" ")]),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex + "-" + childItemIndex),attrs:{"id":("more-actions-" + itemIndex + "-" + childItemIndex),"data":item},on:{"delete":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])])])]):_vm._e()]})]:_vm._e()]})]}}],null,false,3630180328)})]:_c('div',{staticClass:"py-5 font-bold text-center text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t('components.acl.doNotHavePermission'))+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }